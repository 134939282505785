// import axios, { AxiosRequestConfig } from 'axios';
// import { Console } from 'console';
// import Cookies from 'js-cookie';

// interface CallAPIProps extends AxiosRequestConfig {
//   token?: boolean;
//   serverToken?: string;
// }

// export default async function callAPI({
//   url,
//   method,
//   data,
//   token,
//   serverToken,
//   params,
// }: CallAPIProps) {
//   let headers = {};
//   if (serverToken) {
//     headers = {
//       Authorization: `Bearer ${serverToken}`,
//     };
//   } else if (token) {
//     const tokenCookies = Cookies.get('tkn');
//     if (tokenCookies) {
//       const jwtToken = Buffer.from(tokenCookies, 'base64').toString('ascii');
//       headers = {
//         Authorization: `Bearer ${jwtToken}`,
//         // "Content-Type": 'application/x-www-form-urlencoded',
//       };
//     }
//   }
//   const response = await axios({
//     url,
//     method,
//     data,
//     headers,
//     params,
//   }).catch((err) => err.response);

//   // console.log ("CALL API RESPONSE" + response.data)

//   if (response?.status > 300 || !response) {
//     const res = {
//       error: true,
//       message: response?.data.message || 'Gagal mendapatkan data',
//       data: null,
//     };
//     return res;
//   }

//   const { length } = Object.keys(response.data);
//   const res = {
//     error: false,
//     message: response.data.message,
//     data: length > 1 ? response.data : response.data.data,
//   };

//   return res;
// }

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

interface CallAPIProps extends AxiosRequestConfig {
  token?: boolean;
  serverToken?: string;
}

interface APIResponse {
  error: boolean;
  message: string;
  data: any;
}

export default async function callAPI({
  url,
  method,
  data,
  token,
  serverToken,
  params,
}: CallAPIProps): Promise<APIResponse> {
  try {
    // Konfigurasi default headers
    let headers: any = {};

    // Set header authorization jika serverToken atau token disediakan
    if (serverToken) {
      headers.Authorization = `Bearer ${serverToken}`;
    } else if (token) {
      const tokenCookies = Cookies.get('tkn');
      if (tokenCookies) {
        const jwtToken = Buffer.from(tokenCookies, 'base64').toString('ascii');
        headers.Authorization = `Bearer ${jwtToken}`;
      }
    }

    // Konfigurasi axios request
    const config: AxiosRequestConfig = {
      url,
      method,
      data,
      headers,
      params,
    };

    // Kirim request ke server
    const response: AxiosResponse = await axios(config);

    // Periksa status respons
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      const length = Object.keys(data).length;

      return {
        error: false,
        message: data.message || 'Request successful',
        data: length > 1 ? data : data.data,
      };
    } else {
      // Jika status tidak dalam rentang 200-299, anggap sebagai error
      return {
        error: true,
        message: response.data.message || 'Gagal mendapatkan data',
        data: null,
      };
    }
  } catch (error: any) {
    // Tangani error yang dilempar oleh axios
    console.error('Error in callAPI:', error);

    // Kembalikan response error yang lebih informatif
    return {
      error: true,
      message:
        error.response?.data?.message ||
        error.message ||
        'Terjadi kesalahan pada API',
      data: null,
    };
  }
}
